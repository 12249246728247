import {
  SET_RESOURCES,
  SET_SEARCH_TERM,
} from "../constants/learningResources";

const initialState = {
  resources: [],
  searchTerm: "",
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_RESOURCES:
      return { ...state, resources: payload };
    case SET_SEARCH_TERM:
      return { ...state, searchTerm: payload };
    default:
      return state;
  }
};
