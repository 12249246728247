import {
  SET_ACTIVE_FILTERS,
  SET_SELECTED_FOUNDRY_MEMBER,
  SET_FOUNDRY_MEMBER_MODAL_STATE,
  SET_SEARCH_TERM,
} from "../constants/meetTheFoundry";

const initialState = {
  activeFilters: [],
  searchTerm: "",
  selectedFoundryMember: {},
  foundryMemberModalState: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ACTIVE_FILTERS:
      return { ...state, activeFilters: payload };
    case SET_SELECTED_FOUNDRY_MEMBER:
      return { ...state, selectedFoundryMember: payload };
    case SET_FOUNDRY_MEMBER_MODAL_STATE:
      return { ...state, foundryMemberModalState: payload };
    case SET_SEARCH_TERM:
      return {...state, searchTerm: payload };
    default:
      return state;
  }
};
