import {
    SET_FIRST_NAME, SET_LAST_NAME, SET_EMAIL, SET_MAILING_LIST
} from '../constants/registration';

const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    mailingList: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case SET_FIRST_NAME:
            return { ...state, firstName: payload };
        case SET_LAST_NAME:
            return { ...state, lastName: payload };
        case SET_EMAIL:
            return { ...state, email: payload };
        case SET_MAILING_LIST:
            return { ...state, mailingList: payload };
        default:
            return state;
    }
};

