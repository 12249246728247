const initialState = {
  checkedFellowshipRegions: [],
  checkedFellowshipYears: [],
  searchName: "",
  searchResults: [],
  fellowshipSearchResultsLimit: 12,
  footerHeight: 0,
  pendingCourseEnrollmentNotification: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_CHECKED_FELLOWSHIP_REGIONS":
      return {
        ...state,
        checkedFellowshipRegions: action.checkedRegions,
      };
    case "SET_CHECKED_FELLOWSHIP_YEARS":
      return {
        ...state,
        checkedFellowshipYears: action.checkedYears,
      };
    case "SET_FELLOWSHIP_SEARCH_NAME":
      return { ...state, searchName: action.searchName };
    case "SET_FELLOWS_SEARCH_RESULTS":
      return { ...state, searchResults: action.searchResults };
    case "SET_FELLOWS_SEARCH_RESULTS_LIMIT":
      return { ...state, fellowshipSearchResultsLimit: action.resultsLimit };
    case "SET_FOOTER_HEIGHT":
      return { ...state, footerHeight: action.footerHeight };
    case "SET_PENDING_COURSE_ENROLLMENT_NOTIFICATION":
      return {
        ...state,
        pendingCourseEnrollmentNotification:
          action.pendingCourseEnrollmentNotification,
      };
    default:
      return state;
  }
};
