import {
    SET_NAME, SET_BILLING_COUNTRY, SET_PROVINCE, SET_ADDRESS, SET_CITY, SET_ZIP, SET_SUGGESTED_PRICE
  } from '../constants/payment';

const initialState = {
    name: "",
    billingCountry: null,
    province: null,
    address: "",
    city: "",
    zip: "",
    suggestedPrice: "",
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case SET_NAME:
        return { ...state, name: payload };
      case SET_BILLING_COUNTRY:
        return { ...state, billingCountry: payload };
      case SET_PROVINCE:
        return { ...state, province: payload };
      case SET_ADDRESS:
        return { ...state, address: payload };
      case SET_CITY:
        return { ...state, city: payload };
      case SET_ZIP:
        return { ...state, zip: payload };
      case SET_SUGGESTED_PRICE:
        return { ...state, suggestedPrice: payload };
      default:
        return state;
    }
  };
  
  