import {
    SET_GRID_COURSES, SET_LANGUAGES, SET_THEMES, SET_SUBJECTS, SET_FORMATS, SET_LENGTHS, SET_SEARCH_TERM
  } from '../constants/course';

const initialState = {
    gridCourses: [],
    languages: [],
    themes: [],
    subjects: [],
    formats: [],
    lengths: [],
    searchTerm: "",
  };
  
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case SET_GRID_COURSES:
        return { ...state, gridCourses: payload };
      case SET_LANGUAGES:
        return { ...state, languages: payload };
      case SET_THEMES:
        return { ...state, themes: payload };
      case SET_SUBJECTS:
        return { ...state, subjects: payload };
      case SET_FORMATS:
        return { ...state, formats: payload };
      case SET_LENGTHS:
        return { ...state, lengths: payload };
      case SET_SEARCH_TERM:
        return { ...state, searchTerm: payload };
      default:
        return state;
    }
  };
  
  